import { navigate } from 'gatsby'
import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { RegisterForm } from '../components/register-form'

import { useUserbaseUserState } from '../services/userbase/userProvider'

const RegisterPage = () => {
  const { user, ready } = useUserbaseUserState()

  if (!ready) {
    // @TODO should be seeing splash screen
    return null
  }

  if (user) {
    // @TODO navigate to app
    navigate('/', { replace: true })
    return null
  }

  return (
    <Layout>
      <SEO title="Register" />

      <RegisterForm>
        <h1 className="hd-xl text-blue-bright mb-24">Register</h1>
      </RegisterForm>
    </Layout>
  )
}

export default RegisterPage
