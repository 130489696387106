import React from 'react'
import { node } from 'prop-types'
import { Link } from 'gatsby'

import { Form } from '../form'
import { Button } from '../button'
import { Input } from '../input'
import { Checkbox } from '../checkbox'
import { ForgotPasswordMessage } from '../forgot-password-message'

import { useUserbaseUser } from '../../services/userbase'
import {
  USERNAME_ERRORS,
  PASSWORD_ERRORS,
  EMAIL_ERRORS,
} from '../../domains/userbase'

export const RegisterForm = ({ children }) => {
  const { signUp } = useUserbaseUser()
  const [username, setUsername] = React.useState('')
  const [matchPassword, setMatchPassword] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [rememberMe, setRememberMe] = React.useState(true)
  const [errors, setErrors] = React.useState({
    username: '',
    email: '',
    password: '',
    matchPassword: '',
  })

  const handleSignUp = async () => {
    const response = await signUp(username, password, email, rememberMe)

    if (response.status !== 200) {
      if (USERNAME_ERRORS.includes(response.name)) {
        setErrors({
          ...errors,
          username: response.message,
        })
      } else if (EMAIL_ERRORS.includes(response.name)) {
        setErrors({
          ...errors,
          email: response.message,
        })
      } else if (PASSWORD_ERRORS.includes(response.name)) {
        setErrors({
          ...errors,
          password: response.message,
        })
      } else {
        // @TODO global banner error
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (password !== matchPassword) {
      setErrors({
        ...errors,
        matchPassword: "Passwords don't match",
      })

      return
    }

    handleSignUp()
  }

  return (
    <Form className="mx-auto" onSubmit={handleSubmit}>
      {children}

      <Input
        className="mb-24"
        id="username"
        value={username}
        setValue={setUsername}
        error={errors.username}
        onErrorClear={() =>
          setErrors({
            ...errors,
            username: '',
          })
        }
      >
        Username
      </Input>

      <Input
        className="mb-24"
        id="email"
        value={email}
        setValue={setEmail}
        // type="email"
        error={errors.email}
        onErrorClear={() =>
          setErrors({
            ...errors,
            email: '',
          })
        }
      >
        Email
      </Input>

      <Input
        className="mb-24"
        id="password"
        value={password}
        setValue={setPassword}
        type="password"
        error={errors.password}
        onErrorClear={() =>
          setErrors({
            ...errors,
            password: '',
          })
        }
      >
        Password
      </Input>

      <Input
        className="mb-24"
        id="match-password"
        value={matchPassword}
        setValue={setMatchPassword}
        type="password"
        error={errors.matchPassword}
        onErrorClear={() =>
          setErrors({
            ...errors,
            matchPassword: '',
          })
        }
      >
        Confirm Password
      </Input>

      <Checkbox
        className="mb-24"
        id="remember-me"
        value={rememberMe}
        setValue={setRememberMe}
      >
        Keep me logged in
      </Checkbox>

      <ForgotPasswordMessage />

      <Button
        className="block min-w-200 mb-32"
        disabled={
          !username || !password || !password || !matchPassword || !email
        }
        type="submit"
      >
        Submit
      </Button>

      <Link
        className="block text-blue-organic util-label no-underline mb-24"
        to="/login"
      >
        Already have an account?
      </Link>
    </Form>
  )
}

RegisterForm.propTypes = {
  children: node,
}
